import { clpp } from "./cl.core.js";
import './cl.mse.js';
let g = {};
const _ = clpp._;
var f = function (window) {
  'use strict';

  var $H = "CLOSED-CAPTIONS",
    aI = "Could not parse MP4 segment start time.",
    bI = "DURATION",
    cI = "EXT-X-STREAM-INF",
    dI = "Failed to infer discontinuity number",
    eI = "Guessing audio-only",
    fI = "METHOD",
    gI = "SAMPLE-AES-CTR",
    hI = "SUBTITLES",
    iI = "Two EXT-X-KEY tags, one with METHOD\x3dAES-128 are applied to one ",
    jI = "application/vnd.apple.mpegurl",
    kI = "sample",
    lI = "whole-segment",
    mI = function (a, b) {
      return _.I(a, b, void 0, {
        required: !0
      });
    },
    nI = function (a) {
      if (1 > a.a) throw _.Pl();
      --a.a;
    },
    oI = function (a, b) {
      for (const c in a) {
        if (!(c in b)) return !1;
        const d = a[c],
          e = b[c];
        if (d && typeof d === _.De && e && typeof e === _.De) {
          if (!oI(d, e)) return !1;
        } else if (void 0 !== d && void 0 !== e && d !== e) return !1;
      }
      return !0;
    },
    pI = function (a, b) {
      if (!oI(a, b)) return !1;
      for (const c in b) {
        if (!(c in a)) return !0;
        const d = a[c],
          e = b[c];
        if (!d && e || d && typeof d === _.De && e && typeof e === _.De && pI(d, e)) return !0;
      }
      return !1;
    },
    qI = function (a) {
      if (null !== a && void 0 !== a) return Number(a);
    },
    rI = function* (a) {
      let b = -1,
        c = void 0,
        d = void 0;
      for (const e of a) 0 <= b && (yield {
        tf: b,
        item: d,
        lh: c,
        next: e
      }), b++, c = d, d = e;
      -1 !== b && (yield {
        tf: b,
        lh: c,
        item: d,
        next: void 0
      });
    },
    sI = function (a, b) {
      return a.filter(c => c.name === b);
    },
    tI = function (a) {
      const b = /^[a-zA-Z0-9_-]+$/,
        c = new Set();
      a.forEach(d => {
        const e = _.I(d, "VALUE"),
          f = _.I(d, "NAME"),
          g = _.I(d, "IMPORT"),
          h = _.I(d, "QUERYPARAM");
        if (1 !== [f, g, h].filter(Boolean).length || f && null == e) throw new _.G(_.E, 4, 4044, {
          message: "Invalid tag.",
          text: d.toString()
        });
        d = f || g || h;
        if (c.has(d)) throw new _.G(_.E, 4, 4044, `EXT-X-DEFINE redefined variable "${d}".`);
        if (!b.test(d)) throw new _.G(_.E, 4, 4044, `EXT-X-DEFINE invalid variable name "${d}".`);
        c.add(d);
      });
    },
    uI = function (a, b) {
      const c = Array.isArray(b) ? b : [b];
      return a.filter(d => {
        d = mI(d, _.Kb);
        return c.includes(d);
      });
    },
    vI = function (a, b) {
      return a.some(c => c.name === b);
    },
    wI = function (a, b, c) {
      c = void 0 === c ? {} : c;
      a = a.find(d => d.name === b) || null;
      if (!a && c.required) throw new _.G(_.E, 4, 4024, {
        missingTag: b
      });
      return a;
    },
    xI = function (a) {
      var b = wI(a, "EXT-X-TILES");
      if (!b) return {
        Uc: 1,
        kd: 1,
        durationPerTile: null
      };
      a = _.I(b, "LAYOUT") || "1x1";
      b = _.I(b, bI);
      a = _.BB(a);
      return {
        Uc: a.Uc,
        kd: a.kd,
        durationPerTile: b ? Number(b) : null
      };
    },
    yI = function (a, b, c) {
      c = void 0 === c ? !0 : c;
      a = new _.ci(a);
      b = new _.ci(b);
      b = a.resolve(b).toString();
      return c ? b : decodeURI(b);
    },
    zI = function (a) {
      const b = new Set();
      a.variants.forEach(c => {
        c.audio && b.add(c.audio);
        c.video && b.add(c.video);
      });
      a.textStreams.forEach(c => b.add(c));
      a.thumbStreams.forEach(c => b.add(c));
      return Array.from(b);
    },
    AI = function (a, b) {
      Array.isArray(a) || (a = [a]);
      a.forEach(c => {
        for (const d of zI(c)) b(d, c);
      });
    },
    BI = function (a) {
      return a.some(b => vI(b.aa, _.Ea));
    },
    CI = function (a) {
      return a.type === _.p || a.type === _.t;
    },
    DI = function (a) {
      if (wI(a, _.Ea)) return _.Tb;
      a = wI(a, _.Ja);
      if (!a) return "LIVE";
      if (a.value === _.Tb) return _.Tb;
      if ("EVENT" === a.value) return "EVENT";
      throw new _.G(_.E, 4, 4044, `Invalid EXT-X-PLAYLIST-TYPE value: ${a.value}`);
    },
    EI = function (a) {
      a = {
        AUDIO: _.p,
        VIDEO: _.t,
        SUBTITLES: _.F,
        "CLOSED-CAPTIONS": _.F
      }[a.toUpperCase()];
      if (!a) throw new _.G(_.E, 4, 4044, "EXT-X-MEDIA tag has an invalid TYPE attribute.");
      return a;
    },
    FI = function (a, b, c) {
      c = void 0 === c ? !1 : c;
      var d = 0;
      let e = null;
      if (a) {
        d = a.split("@");
        a = Number(d[0]);
        if (0 >= a) throw new _.G(_.E, 4, 4044, "Byte range length must be positive.");
        if (d[1]) {
          if (d = Number(d[1]), 0 > d) throw new _.G(_.E, 4, 4044, "Byte range start byte must not be negative.");
        } else {
          if (c) throw new _.G(_.E, 4, 4044, "Invalid byte range, offset is required but missing.");
          if (null == b) throw new _.G(_.E, 4, 4044, "Invalid byte range, cannot calculate offset based on previous byte ranges.");
          d = b + 1;
        }
        e = d + a - 1;
      }
      return {
        wa: d,
        ka: e
      };
    },
    GI = function (a, b) {
      a = wI(a, _.za);
      return FI(a ? a.value : null, b);
    },
    HI = function (a) {
      a = wI(a, "EXTINF", {
        required: !0
      }).value.split(",");
      return {
        duration: Number(a[0]),
        title: a[1] || null
      };
    },
    II = function (a, b) {
      b = void 0 === b ? {} : b;
      const c = {
        Ze: !1,
        og: !1,
        pg: null,
        Rg: null,
        Gf: null
      };
      a = wI(a, _.Na, {
        required: b.Mf
      });
      if (!a) return c;
      const d = e => null == e ? null : Number(e);
      c.Ze = "YES" === _.I(a, "CAN-BLOCK-RELOAD");
      c.og = "YES" === _.I(a, "CAN-SKIP-DATERANGES");
      c.pg = d(_.I(a, "CAN-SKIP-UNTIL"));
      c.Rg = d(_.I(a, "HOLD-BACK"));
      c.Gf = d(_.I(a, "PART-HOLD-BACK", void 0, {
        required: b.Mf
      }));
      return c;
    },
    JI = function (a, b, c) {
      c = void 0 === c ? !1 : c;
      a = _.I(a, "BYTERANGE");
      return FI(a, b, c);
    },
    LI = function (a, b) {
      const c = GI(a.aa, null);
      return new KI(0, 0, 0, () => [a.a], c.wa, c.ka, 0, b);
    },
    MI = function (a, b) {
      if (null == a) return null;
      a = Object.assign({}, a);
      null == a.iv && (a.iv = _.Yi(new Uint8Array(12), new Uint8Array([b >> 24 & 255, b >> 16 & 255, b >> 8 & 255, b & 255])));
      return a;
    },
    NI = function (a) {
      let b = Infinity,
        c = Infinity;
      zI(a).filter(CI).forEach(d => {
        {
          d = d.U;
          var e = d[0].startTime;
          const f = d[d.length - 1].endTime;
          d = e;
          e = f - e;
        }
        b = Math.min(b, d);
        c = Math.min(c, e);
      });
      return {
        Gc: b,
        duration: c
      };
    },
    OI = function (a) {
      return a.map(b => {
        ({
          duration: b
        } = NI(b));
        return b;
      }).reduce((b, c) => b + c, 0);
    },
    PI = function (a) {
      return (a.video ? a.video.Ca : "") + " - " + (a.audio ? a.audio.Ca : "");
    },
    QI = function (a, b, c, d) {
      if (b === _.Tb) return {
        delay: 0,
        jd: 0
      };
      b = wI(a, _.Ia);
      if (c && b) {
        c = Number(mI(b, "PART-TARGET"));
        if (null != d) return {
          delay: d,
          jd: c
        };
        ({
          Gf: d
        } = II(a, {
          Mf: !0
        }));
        return {
          delay: d,
          jd: c
        };
      }
      a = wI(a, _.Oa, {
        required: !0
      });
      a = Number(a.value);
      return null != d ? {
        delay: d,
        jd: a
      } : {
        delay: 3 * a,
        jd: a
      };
    },
    SI = function (a) {
      const b = new Map();
      a.forEach(c => {
        var d = `${RI(c).type}:${RI(c).groupId}`;
        b.has(d) ? (d = b.get(d), d.includes(c) || d.push(c)) : b.set(d, [c]);
      });
      return b;
    },
    TI = function (a) {
      const b = new Map();
      for (const c of a) {
        a = _.I(c, _.ab);
        a = _.qi(a || _.$f);
        const d = mI(c, _.Ua),
          e = mI(c, "INSTREAM-ID");
        b.has(d) || b.set(d, new Map());
        b.get(d).set(e, a);
      }
      return b;
    },
    VI = function (a) {
      a = a.audio.concat(a.video);
      for (const b of a) a = RI(b).codecs.split(",").filter(c => "mp4a.40.34" !== c), UI(b, a);
    },
    WI = function (a, b) {
      if (!a) return null;
      const c = a.Ca;
      return b.find(d => RI(d).Ca === c) || null;
    },
    XI = function (a) {
      for (const b of a) if (b.startsWith("#EXT-") && (a = _.cj(0, b), _.qt.has(a.name))) return 1;
      return 0;
    },
    YI = function (a, b) {
      return a.replace(/\{\$([a-zA-Z0-9_-]+)\}/g, (c, d) => {
        c = b.get(d);
        if (null == c) throw new _.G(_.E, 4, 4044, `Undefined variable '${d}'`);
        return c;
      });
    },
    ZI = function (a, b) {
      a.forEach(c => {
        c.attributes.forEach(d => {
          d.value = YI(d.value, b);
        });
      });
    },
    $I = function (a, b, c) {
      ZI(a, c);
      b.forEach(d => {
        d.a && (d.a = YI(d.a, c));
        ZI(d.aa, c);
        ZI(d.f, c);
      });
    },
    aJ = function (a, b) {
      const c = new Map(),
        d = new URL(b);
      a.forEach(e => {
        var f = _.I(e, "NAME"),
          g = _.I(e, "QUERYPARAM");
        if (f) g = mI(e, "VALUE"), c.set(f, g);else if (g) {
          f = d.searchParams.get(g);
          if (null == f) throw new _.G(_.E, 4, 4044, `Query param '${g}' missing in playlist URI.`);
          if (!f) throw new _.G(_.E, 4, 4044, `Query param '${g}' has no associated value` + " in playlist URI.");
          c.set(g, decodeURIComponent(f));
        }
      });
      return c;
    },
    dJ = function (a, b, c) {
      const d = [];
      for (let e = 1; e < b.length; e++) {
        const f = bJ(a, b[e]);
        d.push(f);
        if (f.name === cI) {
          const g = b[e + 1];
          if (!g) throw new _.G(_.E, 4, 4044, "EXT-X-STREAM-INF is not followed by a URI.");
          f.attributes.push(new _.aj(_.Mb, g));
          e++;
        }
      }
      b = sI(d, "EXT-X-DEFINE");
      tI(b);
      b = aJ(b, c);
      a.a = b;
      $I(d, [], a.a);
      return new cJ(c, 0, d);
    },
    gJ = function (a, b, c) {
      let d = [];
      for (var e = 1; e < b.length;) {
        const f = bJ(a, b[e]);
        if (_.rt.has(f.name)) break;
        d.push(f);
        e++;
      }
      b = eJ(a, c, b.slice(e));
      e = b.U;
      d = d.concat(b.aa);
      a = fJ(a, d, c);
      $I(d, e, a);
      return new cJ(c, 1, d, b.U);
    },
    hJ = function (a, b, c) {
      b = b.trim().split(/\n+/m).map(d => d.trim()).filter(d => d.length && !/^#(?!EXT)/m.test(d));
      if (!/^#EXTM3U($|[ \t\n])/m.test(b[0])) throw new _.G(_.E, 4, 4015);
      return 0 === XI(b) ? dJ(a, b, c) : gJ(a, b, c);
    },
    bJ = function (a, b) {
      return _.cj(a.f++, b);
    },
    eJ = function (a, b, c) {
      const d = [],
        e = [];
      let f = [],
        g = [];
      c.forEach(h => {
        /^(#EXT)/.test(h) ? (h = bJ(a, h), _.qt.has(h.name) || _.tt.has(h.name) ? d.push(h) : _.st.has(h.name) ? "EXT-X-PART" === h.name ? g.push(h) : h.name === _.La && "PART" === _.I(h, _.Kb) && g.push(h) : f.push(h)) : /^#(?!EXT)/m.test(h) || (h = yI(b, h.trim(), !1), e.push(new iJ(h, f, g)), f = [], g = []);
      });
      0 < g.length && e.push(new iJ(null, f, g));
      return {
        U: e,
        aa: d
      };
    },
    fJ = function (a, b, c) {
      b = sI(b, "EXT-X-DEFINE");
      tI(b);
      const d = aJ(b, c);
      b.map(e => _.I(e, "IMPORT")).filter(Boolean).forEach(e => {
        const f = a.a.get(e);
        if (null == f) throw new _.G(_.E, 4, 4044, `Cannot import variable '${e}' as it is not defined ` + "in the master playlist");
        d.set(e, f);
      });
      return d;
    },
    jJ = function (a) {
      return 0 > a ? [] : Array.from({
        length: a
      }, (b, c) => c);
    },
    kJ = function (a, b = 0) {
      return b + 10 <= a.length && 73 === a[b] && 68 === a[b + 1] && 51 === a[b + 2] && 255 > a[b + 3] && 255 > a[b + 4] && 128 > a[b + 6] && 128 > a[b + 7] && 128 > a[b + 8] && 128 > a[b + 9] ? !0 : !1;
    },
    lJ = function (a) {
      function b(h, k, l) {
        let m = "";
        for (; k < l; k++) m += "%" + ("00" + h[k].toString(16)).slice(-2);
        return m;
      }
      function c(h) {
        return h[0] << 21 | h[1] << 14 | h[2] << 7 | h[3];
      }
      function d() {
        throw new _.G(_.E, 4, 4030);
      }
      kJ(a) || d();
      let e = 10;
      a[5] & 64 && (e = e + 4 + c(a.subarray(10, 14)));
      do {
        var f = c(a.subarray(e + 4, e + 8));
        1 > f && d();
        var g = String.fromCharCode(a[e], a[e + 1], a[e + 2], a[e + 3]);
        if ("PRIV" === g) {
          g = a.subarray(e + 10, e + f + 10);
          for (let h = 0; h < g.byteLength; h++) if (0 === g[h]) {
            if ("com.apple.streaming.transportStreamTimestamp" === unescape(b(g, 0, h))) return a = g.subarray(h + 1), f = (a[3] & 1) << 30 | a[4] << 22 | a[5] << 14 | a[6] << 6 | a[7] >>> 2, f *= 4, f += a[7] & 3, Math.round(f / 9E4);
            break;
          }
        }
        kJ(a, e) || d();
        e += 10;
        e += f;
      } while (e < a.byteLength);
      return d();
    },
    mJ = function (a, b) {
      let c = null,
        d = 0;
      try {
        new _.em().N(_.ze, _.Yl).N(_.Xf, _.Yl).N(_.ue, _.Yl).ca("mdhd", g => {
          g.reader.skip(0 === g.version ? 8 : 16);
          d = _.O(g.reader);
          g.parser.stop();
        }).parse(b, !0);
      } catch (g) {
        c = g;
      }
      if (c || !d) throw _.Lu.error("Unable to find timescale in init segment!"), new _.G(_.E, 4, 4030, aI, c);
      let e = 0,
        f = !1;
      try {
        new _.em().N("moof", _.Yl).N("traf", _.Yl).ca("tfdt", g => {
          var h = d;
          if (0 === g.version) h = _.O(g.reader) / h;else {
            {
              const k = _.O(g.reader),
                l = _.O(g.reader);
              if (!(2097151 >= k || h >= Math.pow(2, 12))) throw Error("Cannot accurately parse MP4 timestamp, base time is too high and timescale is \x3c\x3d 2^12");
              h = k / h * Math.pow(2, 32) + l / h;
            }
          }
          e = h;
          f = !0;
          g.parser.stop();
        }).parse(a, !0);
      } catch (g) {
        c = g;
      }
      if (c || !f) throw new _.G(_.E, 4, 4030, aI, c);
      return e;
    },
    nJ = function (a) {
      for (; a.getPosition() < a.Ka() - 1;) {
        const b = a.getPosition();
        if (71 === _.Ql(a)) return nI(a), b;
      }
      return -1;
    },
    oJ = function (a) {
      function b() {
        d.seek(e + 188);
        f = _.Ql(d);
        71 !== f && (d.seek(e + 192), f = _.Ql(d));
        71 !== f && (d.seek(e + 204), f = _.Ql(d));
        71 !== f && c();
        nI(d);
      }
      function c() {
        throw new _.G(_.E, 4, 4030);
      }
      const d = new _.Rm(new DataView(a), !1);
      let e = 0,
        f = 0;
      e = nJ(d);
      0 > e && (_.Lu.error("Unable to find sync byte in TS after scanning."), c());
      for (;;) {
        e = d.getPosition();
        f = _.Ql(d);
        71 !== f && c();
        a = _.Rl(d);
        if (8191 === (a & 8191)) {
          b();
          continue;
        }
        if (!(a & 16384)) {
          b();
          continue;
        }
        a = (_.Ql(d) & 48) >> 4;
        0 !== a && 2 !== a || c();
        3 === a && (a = _.Ql(d), d.skip(a));
        if (1 !== _.O(d) >> 8) {
          b();
          continue;
        }
        d.skip(3);
        a = _.Ql(d) >> 6;
        0 !== a && 1 !== a || c();
        0 === _.Ql(d) && c();
        a = _.Ql(d);
        const g = _.Rl(d),
          h = _.Rl(d);
        return (1073741824 * ((a & 14) >> 1) + ((g & 65534) << 14 | (h & 65534) >> 1)) / 9E4;
      }
    },
    rJ = function (a) {
      const b = pJ(a),
        c = qJ(a);
      a.forEach(d => {
        const e = d.U,
          f = e.findIndex(h => h.ja === b);
        let g = e.findIndex(h => h.ja === c + 1);
        -1 === g && (g = e.length);
        d.U = e.slice(f, g);
      });
    },
    qJ = function (a) {
      a = a.map(b => b.U[b.U.length - 1].ja);
      return Math.min(...a);
    },
    pJ = function (a) {
      a = a.map(b => b.U[0].ja);
      return Math.max(...a);
    },
    uJ = function (a) {
      var b = mI(a, fI);
      if (!sJ.includes(b)) return tJ.error("PlayReady in HLS is only supported with [", sJ.join(", "), "], not", b), null;
      a = mI(a, _.Mb);
      b = _.Bs(a);
      a = null;
      b = _.J(b.data);
      try {
        a = _.kB(_.Ym(b));
      } catch (c) {
        a = b;
      }
      return _.xB(_.vd, [{
        initDataType: _.ad,
        initData: a
      }], "");
    },
    wJ = function (a) {
      if (0 === a.length) return vJ();
      if (a.some(c => c.type === lI) || a.every(c => c.type === _.Ce)) return a[0];
      a = a.filter(c => c.type === kI);
      const b = _.Uh(a.flatMap(c => c.drmInfos));
      return {
        keyInfo: null,
        keyId: a[a.length - 1].keyId,
        type: kI,
        drmInfos: b
      };
    },
    zJ = function (a) {
      a = xJ(a);
      if (a.some(b => yJ(b)) && 1 < a.length) throw new _.G(_.E, 4, 4046, "Two EXT-X-KEY tags, one with METHOD\x3dAES-128 are applied to one playlist.");
    },
    yJ = function (a) {
      return sI(a.aa, _.Fa).some(b => "AES-128" === _.I(b, fI));
    },
    BJ = function (a, b, c) {
      return xJ(a).map(d => AJ(d.aa, b, c));
    },
    CJ = function (a) {
      return a.filter(b => vI(b.aa, _.Fa)).length;
    },
    xJ = function (a) {
      return a.filter(b => vI(b.aa, _.Fa));
    },
    AJ = function (a, b, c) {
      a = sI(a, _.Fa);
      if (0 === a.length) return null;
      var d = mI(a[0], fI);
      if (1 === a.length) {
        if ("NONE" === d) return vJ();
        if ("AES-128" === d) return a = a[0], d = _.I(a, "IV", ""), {
          type: lI,
          keyInfo: {
            method: "AES-128",
            url: yI(b, mI(a, _.Mb)),
            iv: d ? _.Vi((d || "0x").slice(2)) : null
          },
          drmInfos: [],
          keyId: null
        };
      }
      b = a.map(e => mI(e, fI));
      if (!b.every(e => "SAMPLE-AES" === e) && !b.every(e => e === gI)) throw DJ.warn(`EXT-X-KEY methods ${b.join(", ")} are incompatible.`), new _.G(_.E, 4, 4044, "Multiple EXT-X-KEY tags with incompatible METHOD values.");
      b = a.map(e => {
        const f = _.I(e, "KEYFORMAT", "identity");
        {
          const g = EJ[f] || null;
          e = g ? g(e, c) : null;
        }
        e || DJ.warn("Unsupported HLS KEYFORMAT", f);
        return e;
      }).filter(Boolean);
      if (0 === b.length) throw new _.G(_.E, 4, 4026);
      return {
        keyInfo: null,
        keyId: b.map(e => e.keyIds).reduce((e, f) => e.concat(f), []).reverse()[0] || null,
        type: kI,
        drmInfos: b
      };
    },
    vJ = function () {
      return {
        type: _.Ce,
        keyInfo: null,
        drmInfos: [],
        keyId: null
      };
    },
    FJ = function (a, b, c) {
      return _.w(function* () {
        const d = b.f(),
          e = _.$B(b.Wa(), b.wa, b.wa + 2048 - 1, a.a.attemptParameters);
        e.type = 2;
        const f = _.$B(b.Wa(), b.wa, b.ka, a.a.attemptParameters);
        f.type = 2;
        d && (f.keyInfo = d);
        try {
          return yield a.f(c ? f : e);
        } catch (g) {
          if (7001 === g.code || c) throw g;
          a.g.warn("Unable to fetch a partial HLS segment! Falling back to a full segment request, which is expensive! Your server should support Range requests and CORS pre-flights.", e.uris[0]);
          try {
            return yield a.f(f);
          } catch (h) {
            throw a.g.error("Failed to fetch media segment to parse its start timestamp.", f.uris[0], h), new _.G(_.E, 4, 4030, "Failed to fetch the media segment.");
          }
        }
      });
    },
    GJ = function (a, b, c, d, e, f) {
      return _.w(function* () {
        a.g.debug("Fetching segment to find start time");
        if ("audio/mpeg" === d || d === _.Jc || d === _.Ic) return 0;
        if (d === _.Hc) {
          var g = yield FJ(a, c, f);
          const h = _.J(g.data);
          return kJ(h) ? lJ(h) : oJ(g.data);
        }
        if (d === _.lg || d === _.Kc) return g = [FJ(a, c, f)], b && g.push(FJ(a, b, !0)), g = yield Promise.all(g), mJ(g[0].data, (g[1] || g[0]).data);
        if (d === _.kg) return g = yield FJ(a, c, f), oJ(g.data);
        if (!_.Ar(`${d.split("/")[0] || ""}/mp4;codecs="${e}"`)) return 0;
        throw new _.G(_.E, 4, 4030);
      });
    },
    HJ = function (a, b) {
      a = a.map(c => c.streams[b]);
      return a.some(c => null == c) ? null : a;
    },
    UI = function (a, b) {
      a.streams.forEach(c => {
        c.codecs = b.join(",");
      });
    },
    IJ = function (a, b) {
      a = a.streams.find(c => {
        c = c.U;
        return c[0].position <= b && b <= c[c.length - 1].position;
      });
      return null == a ? null : a.U.find(c => c.position === b) || null;
    },
    RI = function (a) {
      return a.streams[0];
    },
    JJ = function (a, b) {
      a.streams.forEach(c => {
        c.Nd = b;
      });
    },
    KJ = function (a, b) {
      a.streams.forEach(c => {
        c.width = b;
      });
    },
    LJ = function (a, b) {
      a.streams.forEach(c => {
        c.height = b;
      });
    },
    MJ = function (a, b) {
      a.streams.forEach(c => {
        c.frameRate = b;
      });
    },
    NJ = function (a, b) {
      a.streams.forEach(c => {
        c.bandwidth = b;
      });
    },
    OJ = function (a, b) {
      a = a.map(c => c.a[b]);
      return a.some(c => null == c) ? null : a;
    },
    PJ = function (a, b) {
      const c = new Set(a.map(d => d.ja));
      return b.filter(d => !c.has(d.ja));
    },
    RJ = function (a, b) {
      for (; a[0].ja < b[0].ja;) a.splice(0, 1);
      var c = a[0];
      let d = b[0];
      c.startTime === d.startTime && c.endTime === d.endTime || QJ(c, d);
      const e = a.length - 1;
      c = a[e];
      d = b[e];
      0 < e && (c.startTime !== d.startTime || c.endTime !== d.endTime) && QJ(c, d);
      for (c = e + 1; c < b.length;) a.push(b[c]), c++;
    },
    WJ = function (a, b, c, d, e) {
      var f = b[0].length();
      f = jJ(f).map(g => {
        var h = OJ(b, g) || [];
        const k = HJ(c, g) || [];
        g = HJ(d, g) || [];
        {
          const l = [...SJ(h), ...k, ...g].filter(Boolean);
          TJ(l);
          const {
            ja: m,
            duration: n
          } = l[0];
          h = {
            configurationId: a,
            variants: h,
            textStreams: k,
            thumbStreams: g,
            startTime: 0,
            id: `P${m}`,
            endTime: n,
            duration: n,
            ja: m
          };
        }
        return h;
      });
      e && UJ(f[0]);
      VJ(f);
      return f;
    },
    SJ = function (a) {
      a = new Set(a.flatMap(b => [b.video, b.audio]).filter(Boolean));
      return Array.from(a);
    },
    UJ = function (a) {
      const b = SJ(a.variants)[0].U[0].Ba;
      a.startTime += b;
      a.endTime += b;
    },
    VJ = function (a) {
      for (let b = 0; b < a.length; b++) {
        const c = a[b],
          d = a[b - 1];
        c.startTime = d ? d.endTime : c.startTime;
        c.endTime = c.startTime + c.duration;
      }
    },
    QJ = function (a, b) {
      a.startTime = b.startTime;
      a.endTime = b.endTime;
      a.duration = b.duration;
      XJ(a.variants, b.variants);
      YJ(zI(a), zI(b));
    },
    XJ = function (a, b) {
      const c = new Map(a.map(d => [PI(d), d]));
      b.forEach(d => {
        c.get(PI(d)).drmInfos = d.drmInfos;
      });
    },
    YJ = function (a, b) {
      const c = new Map(a.map(d => [d.Ca, d]));
      b.forEach(d => {
        const e = c.get(d.Ca);
        e.U = d.U;
        e.segmentIndex.replace(d.U);
        e.Gc = d.Gc;
        e.ee = d.ee;
        e.duration = d.duration;
        e.ef = d.ef;
        e.encrypted = d.encrypted;
        e.drmInfos = d.drmInfos;
        e.keyId = d.keyId;
      });
    },
    TJ = function (a) {
      const b = ZJ(a);
      a.forEach(c => {
        _.WB(c.segmentIndex, b);
        c.duration = b;
        c.ee = c.Gc + b;
      });
    },
    ZJ = function (a) {
      let b = Infinity;
      a.filter(CI).forEach(c => {
        b = Math.min(b, c.duration);
      });
      return b;
    },
    $J = function (a, b) {
      AI(b, (c, d) => {
        _.iC(a, c.U, d.startTime);
      });
      _.aC(a.a, b[0].startTime, b[b.length - 1].endTime);
    },
    aK = function (a) {
      return null === a.j ? [] : [a.j.audio, a.j.video].filter(b => b).map(b => b.Ca);
    },
    bK = function (a, b) {
      return _.w(function* () {
        var c = _.ij([b], a.a.attemptParameters);
        c.type = 1;
        c = yield a.G(c);
        let d = "";
        try {
          d = _.Ei(c.data);
        } catch (e) {
          throw new _.G(_.E, 4, 4042);
        }
        d = d.replace(/\r\n|\r(?=[^\n]|$)/gm, "\n");
        d = d.trim();
        return {
          text: d,
          response: c
        };
      });
    },
    hK = function (a, b) {
      return _.w(function* () {
        var c = cK(a, b, a.w, "");
        if (1 === c.type) {
          c = _.mt(c.U[0].a) || "";
          c = null != _.lt.video[c] ? _.t : null != _.lt.audio[c] ? _.p : null;
          var d = "";
          c === _.t ? d = "avc1.42E01E" : c === _.p && (d = "mp4a.40.2");
          {
            c = a.M;
            var e = a.w;
            d = a.a.hlsDefaultCodecs || d;
            var f = [];
            const q = [];
            q.push(new _.aj(_.ka, "0"));
            q.push(new _.aj(_.Mb, e));
            d.length && q.push(new _.aj(_.na, d));
            f.push(new _.bj(c.f++, cI, q));
            c = new cJ(e, 0, f);
          }
        }
        d = c.aa;
        c = sI(d, "EXT-X-MEDIA");
        e = sI(d, cI);
        d = sI(d, "EXT-X-IMAGE-STREAM-INF");
        c = {
          Fc: {
            jg: uI(c, ["AUDIO", _.Sb]),
            closedCaptions: uI(c, $H),
            Eh: uI(c, hI)
          },
          ld: e,
          ae: d
        };
        const {
          Zg: g,
          periods: h,
          nh: k,
          Gh: l
        } = yield dK(a, c);
        if (!a.D) throw new _.G(_.E, 7, 7001);
        eK(a, h);
        c = !0;
        e = null;
        d = 0;
        fK(a) || (c = !1, e = 0, d = g.delay);
        f = a.a.timeline || {};
        f.type = void 0;
        e = new _.LH(f, e, d, !0, void 0, !!a.a.lowLatencyMode);
        _.eC(e, c);
        $J(e, h);
        k && _.dC(e, k);
        c = e;
        if (!fK(a)) {
          a.F = g.jd;
          e = 0;
          for (var m of a.f.values()) e = Math.max(e, m.streams[0].Gc);
          for (m = 0; 95443.7176888889 <= e;) m += 95443.7176888889, e -= 95443.7176888889;
          if (m) {
            a.g.debug("Offsetting live streams by", m, "to compensate for rollover");
            for (var n of a.f.values()) e = n.streams[0], 95443.7176888889 > e.Gc ? (a.g.debug("Offset applied to", e.type), e.segmentIndex.offset(m)) : a.g.debug("Offset NOT applied to", e.type);
          }
        }
        n = OI(h);
        "LIVE" === a.l || "EVENT" === a.l ? (n = Math.max(n, c.J), isNaN(a.a.availabilityWindowOverride) || (n = a.a.availabilityWindowOverride), c.A = n) : a.l === _.Tb && (c.f = n);
        n = gK(a, l);
        n = {
          presentationTimeline: c,
          periods: h,
          minBufferTime: 0,
          cues: n
        };
        a.g.debug("HLS playlist parsed", n);
        return n;
      });
    },
    jK = function (a, b) {
      return Promise.all(b.map(c => iK(a, c)));
    },
    lK = function (a, b) {
      b.forEach(c => {
        a: {
          var d = a.f.get(c.a),
            e = !fK(a),
            f = wI(c.aa, _.Da);
          if (f) var g = Number(f.value);else {
            d ? (f = d.streams[d.streams.length - 1].U, f = f[f.length - 1].ja) : f = 0;
            if (e && 0 < f) try {
              {
                const k = wI(c.aa, _.Ha);
                if (!k) throw kK.error("Failed to infer discontinuity number, because EXT-X-MEDIA-SEQUENCE tag is missing."), Error(dI);
                const l = Number(k.value),
                  m = IJ(d, l);
                if (!m) throw kK.error("Failed to infer discontinuity number, could not find the appropriate segment in the previous playlist" + ` (segment position = ${l})`), Error(dI);
                var h = m.ja;
              }
              g = h;
              break a;
            } catch (k) {
              throw new _.G(_.E, 4, 4024, "EXT-X-DISCONTINUITY-SEQUENCE tag is required for discontinuous HLS live streams.");
            }
            g = 0;
          }
        }
        c = c.U || [];
        for (const k of c) vI(k.aa, _.Ca) && g++, k.ja = g;
      });
      rJ(b);
    },
    oK = function (a, b) {
      return _.w(function* () {
        const {
          textStreams: c,
          thumbStreams: d,
          kg: e
        } = yield mK(a, b);
        var f = a.B.periods[0].variants;
        a.a.lowLatencyMode && null !== a.j && (f = f.filter(g => g.id === a.j.id));
        f = f.map(g => {
          const h = WI(g.audio, e),
            k = WI(g.video, e);
          g = nK(a, h, k, g.bandwidth);
          if (!g) throw new _.G(_.E, 4, 4047, {
            message: "Video and audio are encrypted in an incompatible way.",
            ki: k ? RI(k).Ca : void 0,
            audio: h ? RI(h).Ca : void 0
          });
          return g;
        });
        return WJ(a.D.configurationId, f, c, d, !0);
      });
    },
    eK = function (a, b) {
      fK(a) || 2 !== a.a.timeline.version || (a.H || (a.H = b[0].startTime), _.eD(b, -a.H));
    },
    pK = function (a, b) {
      b = b.flatMap(d => d.U[0].aa);
      b = wI(b, _.Ma);
      if (!b) return null;
      const c = new Date(b.value).getTime();
      return isNaN(c) ? (a.g.warn("Failed to parse EXT-X-PROGRAM-DATE-TIME tag " + `value (value: ${b.value}).`), null) : c / 1E3;
    },
    qK = function (a, b, c) {
      b = b.find(d => null !== wI(d.aa, _.Ba));
      if (!b) return [];
      if (null === c) throw new _.G(_.E, 4, 4024, "EXT-X-PROGRAM-DATE-TIME tag is required when EXT-X-DATERANGE tags are present");
      return b.aa.filter(d => d.name === _.Ba).map(d => {
        a: {
          var e = a.A,
            f = !fK(a),
            g = _.I(d, "ID"),
            h = _.I(d, "START-DATE");
          if (null === g || null === h) _.pt.warn("Invalid  EXT-X-DATERANGE missing mandatory ID or START-DATE attribute, ignoring the EXT-X-DATERANGE tag.", d), d = null;else {
            e = f ? e - c : -c;
            var k = new Date(h);
            h = k.getTime() / 1E3 + e;
            g = {
              id: g,
              startTime: h,
              startDate: k
            };
            f = qI(_.I(d, bI));
            void 0 !== f && 0 <= f && (g.duration = f);
            var l = qI(_.I(d, "PLANNED-DURATION"));
            void 0 !== l && 0 <= l && (g.plannedDuration = l);
            var m = _.I(d, "CLASS");
            null !== m && (g.schemeIdUri = m);
            var n = _.I(d, "END-DATE");
            n = n ? new Date(n) : null;
            null !== n && n.getTime() >= k.getTime() && (g.endDate = n);
            {
              const v = d.attributes;
              if (null == v) var q = null;else {
                k = {};
                for (q of v) q.name.trim().startsWith("X-") && (k[q.name] = q.value);
                q = _.Rg(k) ? k : null;
              }
            }
            q = q || {};
            if (k = _.I(d, "CUE")) q.Oh = k.split(",");
            if (m === _.td) {
              k = d.attributes;
              if (null == k) var u = null;else {
                m = {};
                for (u of k) switch (u.name) {
                  case _.Zb:
                    m[_.Zb] = u.value;
                    break;
                  case _.Yb:
                    m[_.Yb] = u.value;
                    break;
                  case _.ac:
                    m[_.ac] = qI(u.value);
                    break;
                  case _.$b:
                    m[_.$b] = qI(u.value);
                    break;
                  case "X-SNAP":
                    m["X-SNAP"] = u.value.split(",");
                    break;
                  case "X-RESTRICT":
                    m["X-RESTRICT"] = u.value.split(",");
                }
                m[_.Zb] && m[_.Yb] || !m[_.Zb] && !m[_.Yb] ? (_.pt.warn("Invalid EXT-X-DATERANGE Interstitial attributes X-ASSET-URI, X-ASSET-LIST"), u = null) : u = _.Rg(m) ? m : null;
              }
              if (null === u) {
                _.pt.warn("Invalid interstitial attributes, ignoring the EXT-X-DATERANGE tag.", d);
                d = null;
                break a;
              }
              q = Object.assign(q, u);
            }
            _.Rg(q) && (g.customAttributes = q);
            g.endTime = n ? n.getTime() / 1E3 + e : f ? h + f : l ? h + l : h;
            g.type = _.Ch;
            d = g;
          }
        }
        return d;
      }).filter(Boolean);
    },
    gK = function (a, b) {
      const c = new Set(b.map(d => d.id));
      a.o.forEach((d, e) => {
        c.has(e) || a.o.delete(e);
      });
      for (const d of b) {
        if ((b = a.o.get(d.id)) && b.startTime !== d.startTime) a.D.onError(new _.G(1, 4, 4044, "EXT-X-DATERANGE START-DATE has changed for" + ` ID=${d.id}`));
        const e = {
          configurationId: a.D.configurationId,
          schemeIdUri: d.schemeIdUri,
          customAttributes: d.customAttributes,
          value: "",
          startTime: d.startTime,
          endTime: d.endTime,
          id: d.id,
          type: _.Ch,
          eventElement: null
        };
        b && !pI(b, e) || a.o.set(d.id, e);
      }
      return Array.from(a.o.values());
    },
    rK = function (a, b, c) {
      a.l = _.Tb;
      _.eC(b, !0);
      b.f = c[c.length - 1].endTime;
    },
    cK = function (a, b, c, d) {
      a = hJ(a.M, b, c);
      a.a = d;
      return a;
    },
    dK = function (a, b) {
      return _.w(function* () {
        var c = TI(b.Fc.closedCaptions),
          d = sK(a, b.ld),
          e = yield tK(a, b),
          f = Object.values(e).flat().map(h => h.playlist);
        lK(a, f);
        f = f[0];
        var g = DI(f.aa);
        a.l = g;
        a.I = II(f.aa);
        f = QI(f.aa, g, a.a.lowLatencyMode || !1, a.a.suggestedPresentationDelay);
        g = yield uK(a, e.Ve, d);
        c = yield vK(a, e.ld, g, c);
        d = yield wK(a, e.Dh, d);
        g = yield xK(a, e.ae);
        d = WJ(a.D.configurationId, c, d, g, !fK(a));
        a.D.filterAllPeriods(d);
        c = [...e.ld, ...e.Ve].map(h => h.playlist);
        e = pK(a, c);
        c = qK(a, c, e);
        return {
          periods: d,
          nh: e,
          Gh: c,
          Zg: f
        };
      });
    },
    mK = function (a, b) {
      return _.w(function* () {
        const c = g => RI(g.stream).type === _.t || RI(g.stream).type === _.p;
        var d = b.filter(c),
          e = b.filter(g => !c(g)),
          f = g => {
            g = g.map(h => {
              var k = RI(h.stream);
              k = {
                language: k.language,
                channelsCount: k.channelsCount,
                spatialAudio: k.spatialAudio,
                closedCaptions: k.closedCaptions,
                primary: k.primary,
                kind: k.kind || null,
                groupId: k.groupId,
                type: k.type,
                codecs: k.codecs,
                name: k.originalId,
                uri: k.Ca,
                Ld: {
                  width: k.width,
                  height: k.height,
                  frameRate: k.frameRate
                }
              };
              h = Object.assign({}, {
                playlist: h.playlist
              }, k);
              return yK(a, h);
            });
            return Promise.all(g);
          };
        d = yield f(d);
        f = yield f(e);
        zK(a, d.concat(f));
        e = f.filter(g => RI(g).type === _.eB);
        f = f.filter(g => RI(g).type === _.be);
        return {
          textStreams: e,
          thumbStreams: f,
          kg: d
        };
      });
    },
    nK = function (a, b, c, d) {
      const e = [],
        f = (c || b).length();
      for (let m = 0; m < f; m++) {
        var g = b ? b.streams[m] || null : null,
          h = c ? c.streams[m] || null : null,
          k = g,
          l = h;
        k = k && l ? _.EB(k.drmInfos, l.drmInfos) ? _.DB(k.drmInfos, l.drmInfos) : null : k ? k.drmInfos : l ? l.drmInfos : null;
        if (!k) return null;
        l = d;
        g = {
          id: a.R++,
          language: g ? g.language : _.$f,
          primary: !!g && g.primary || !!h && h.primary,
          audio: g,
          video: h,
          bandwidth: l,
          drmInfos: k,
          allowedByApplication: !0,
          allowedByKeySystem: !0
        };
        e.push(g);
      }
      return new AK(e);
    },
    yK = function (a, b) {
      return _.w(function* () {
        const c = b.playlist,
          d = b.codecs,
          e = b.type,
          f = b.Zc,
          g = b.groupId,
          h = b.primary;
        var k = b.kind;
        const l = b.name,
          m = b.channelsCount,
          n = b.spatialAudio,
          q = b.closedCaptions,
          u = b.Ld,
          v = _.qi(f || _.$f);
        if (1 !== c.type) throw new _.G(_.E, 4, 4017);
        const y = yield BK(a, c, e, d),
          A = a.a.lowLatencyMode ? _.UA : _.TA;
        let D = k;
        e !== _.F || k || (D = _.eB);
        k = y.map(H => {
          const X = H.U,
            vb = H.vg,
            je = H.mimeType;
          H = H.ja;
          const ff = X[0].startTime,
            gf = X[X.length - 1].endTime,
            Ya = gf - ff,
            Bb = new _.MH(X),
            Qc = X[0].initSegment || null;
          return {
            id: _.QH++,
            originalId: l,
            createSegmentIndex: Promise.resolve.bind(Promise),
            findSegmentPosition: Bb.f.bind(Bb),
            getSegmentReference: Bb.get.bind(Bb),
            adjustSegmentDurations: Bb.h.bind(Bb),
            getDuration: Bb.getDuration.bind(Bb),
            segmentIndex: Bb,
            segmentLookupStrategy: A,
            initSegmentReference: Qc,
            mimeType: je,
            codecs: d,
            kind: D,
            ef: vb.type,
            encrypted: vb.type === kI,
            keyId: vb.keyId,
            language: v,
            originalLanguage: f || _.$f,
            label: l,
            type: e,
            primary: h,
            trickModeVideo: null,
            emsgSchemeIdUris: null,
            frameRate: u ? u.frameRate : void 0,
            width: u ? u.width : void 0,
            height: u ? u.height : void 0,
            bandwidth: void 0,
            roles: [],
            channelsCount: m,
            spatialAudio: n,
            closedCaptions: q,
            keyInfo: vb.keyInfo,
            U: X,
            drmInfos: vb.drmInfos,
            ja: H,
            Ca: c.a,
            Nd: c.f,
            Gc: ff,
            ee: gf,
            duration: Ya,
            groupId: g
          };
        });
        return new CK(k);
      });
    },
    zK = function (a, b) {
      b.forEach(c => {
        a.f.a.set(RI(c).Ca, c);
      });
    },
    sK = function (a, b) {
      const c = new Map();
      for (const f of b) {
        var d = _.I(f, "AUDIO");
        b = _.I(f, _.Sb);
        var e = _.I(f, hI);
        const g = DK(a, f);
        if (e) {
          const h = _.Sj(_.F, g);
          c.set(`${_.F}:${e}`, h);
          e = g.indexOf(h);
          -1 < e && g.splice(e, 1);
        }
        d && (e = EK(_.p, g), c.set(`${"audio"}:${d}`, e));
        b && (d = EK(_.t, g), c.set(`${"video"}:${b}`, d));
      }
      return c;
    },
    tK = function (a, b) {
      return _.w(function* () {
        const c = yield Promise.all([FK(a, b.Fc.jg), FK(a, b.Fc.Eh), FK(a, b.ld), FK(a, b.ae)]),
          [d, e, f, g] = c;
        return {
          Ve: d,
          Dh: e,
          ld: f,
          ae: g
        };
      });
    },
    uK = function (a, b, c) {
      return _.w(function* () {
        if (0 === b.length) return new Map();
        var d = [GK(a, b[0], c)];
        yield d[0];
        d = d.concat(b.slice(1).map(e => GK(a, e, c)));
        d = yield Promise.all(d);
        return SI(d);
      });
    },
    vK = function (a, b, c, d) {
      return _.w(function* () {
        const e = new Set();
        var f = [...b];
        let g = 0;
        var h = Infinity;
        for (let k = 0; k < f.length; ++k) {
          const l = Number(mI(f[k].tag, _.ka));
          l < h && (h = l, g = k);
        }
        h = f.splice(g, 1).pop();
        h = yield HK(a, h, c, d, e);
        f = f.map(k => HK(a, k, c, d, e));
        f = yield Promise.all(f);
        f.splice(g, 0, h);
        return f.reduce(_.uB, []);
      });
    },
    wK = function (a, b, c) {
      return Promise.all(b.map(d => GK(a, d, c)));
    },
    xK = function (a, b) {
      return Promise.all(b.map(c => IK(a, c)));
    },
    fK = function (a) {
      return a.l === _.Tb;
    },
    FK = function (a, b) {
      return _.w(function* () {
        return (yield Promise.all(b.map(c => _.w(function* () {
          const d = yield JK(a, c);
          return d ? {
            tag: c,
            playlist: d
          } : null;
        })))).filter(Boolean);
      });
    },
    iK = function (a, b) {
      return _.w(function* () {
        var c = new _.ci(RI(b).Nd);
        if (a.a.lowLatencyMode && a.I && a.I.Ze) {
          var d = b.streams[b.streams.length - 1].segmentIndex;
          if (d = d.a[d.a.length - 1]) {
            var e = d.a,
              f = e.length - 1;
            d = {
              $g: d.position,
              hh: e && 0 < e.length ? f : null
            };
          } else d = null;
          if (e = d) d = e.$g, e = e.hh, f = _.Gj(c.a.toString()), f._HLS_msn = d.toString(), null === e && f._HLS_part && delete f._HLS_part, null !== e && (f._HLS_part = e.toString()), d = _.Fj(f, !0), d = new _.ji(d), _.fi(c, d);
        }
        const {
          response: g,
          text: h
        } = yield bK(a, c.toString());
        c = RI(b).Ca;
        d = RI(b).Nd;
        e = g.uri;
        d !== e && (d = e, JJ(b, d));
        c = cK(a, h, d, c);
        return {
          stream: b,
          playlist: c
        };
      });
    },
    DK = function (a, b) {
      var c = _.I(b, _.na, a.a.hlsDefaultCodecs || "avc1.42E01E,mp4a.40.2").split(/\s*,\s*/);
      b = new Set();
      const d = [];
      for (const e of c) c = _.JB(e), b.has(c) ? a.g.debug("Ignoring duplicate codec") : (d.push(e), b.add(c));
      return d;
    },
    EK = function (a, b) {
      if (1 === b.length) return b[0];
      a = _.Sj(a, b);
      if (null != a) return a;
      throw new _.G(_.E, 4, 4025, {
        codecList: b
      });
    },
    GK = function (a, b, c) {
      return _.w(function* () {
        const d = b.tag,
          e = b.playlist;
        return KK(a, e.a, () => _.w(function* () {
          const f = mI(d, _.Ua),
            g = EI(mI(d, _.Kb));
          var h = mI(d, _.Kb).toUpperCase();
          if (EI(h) !== _.F) h = null;else {
            var k = d.getAttribute("FORCED");
            k = !(!k || !k.value || "YES" !== k.value.toUpperCase());
            var l = _.eB;
            h === $H && (l = _.PA);
            k && (l = _.Wd);
            h = l;
          }
          k = c.get(`${g}:${f}`) || "";
          l = _.I(d, _.ab);
          const m = _.I(d, "NAME"),
            n = d.getAttribute("DEFAULT"),
            q = d.getAttribute("AUTOSELECT");
          var u = _.I(d, "CHANNELS");
          u = g === _.p ? u ? parseInt(u.split("/")[0], 10) : null : null;
          var v = g === _.p ? (v = _.I(d, "CHANNELS")) ? v.includes("/JOC") : !1 : !1;
          return yield yK(a, {
            playlist: e,
            codecs: k,
            type: g,
            Zc: l,
            primary: !!n && !!q,
            name: m,
            channelsCount: u,
            spatialAudio: v,
            groupId: f,
            kind: h,
            closedCaptions: null,
            Ld: null
          });
        }));
      });
    },
    IK = function (a, b) {
      return _.w(function* () {
        const c = b.tag,
          d = b.playlist;
        return KK(a, d.a, () => _.w(function* () {
          var e = _.I(c, _.na) || "jpg",
            f = _.I(c, _.ab);
          const g = _.I(c, "NAME");
          e = yield yK(a, {
            playlist: d,
            codecs: e,
            type: _.be,
            Zc: f,
            name: g,
            primary: !1,
            kind: null,
            channelsCount: null,
            spatialAudio: !1,
            closedCaptions: null,
            groupId: null,
            Ld: null
          });
          (f = _.I(c, _.ka)) && NJ(e, Number(f));
          return e;
        }));
      });
    },
    HK = function (a, b, c, d, e) {
      return _.w(function* () {
        var f = b.tag,
          g = Number(mI(f, _.ka));
        if (null != f.getAttribute("FRAME-RATE") || null != f.getAttribute(_.Cb)) {
          var h = _.I(f, "FRAME-RATE");
          f = _.I(f, _.Cb);
          var [k, l] = f ? f.split("x") : [null, null];
          h = {
            frameRate: qI(h),
            width: qI(k),
            height: qI(l)
          };
        } else h = null;
        if (f = yield LK(a, b, h, c, d)) {
          h = f.video;
          f = f.audio;
          var m = 0 < f.length ? f : [null];
          h = 0 < h.length ? h : [null];
          f = [];
          for (const n of m) for (const q of h) {
            m = (q ? RI(q).Ca : "") + " - " + (n ? RI(n).Ca : "");
            if (e.has(m)) {
              a.g.debug("Skipping variant which only differs in text streams.");
              continue;
            }
            const u = nK(a, n, q, g);
            null == u ? a.g.warn("Incompatible DRM info in HLS variant. Skipping.") : (f.push(u), e.add(m));
          }
          g = f;
        } else g = [];
        return g;
      });
    },
    LK = function (a, b, c, d, e) {
      return _.w(function* () {
        var f = b.tag,
          g = b.playlist;
        let h = DK(a, f);
        var k = _.I(f, "AUDIO"),
          l = _.I(f, _.Sb);
        k = k ? d.get(`${"audio"}:${k}`) || [] : [];
        var m = l ? d.get(`${"video"}:${l}`) || [] : [];
        l = [...k, ...m];
        k = {
          audio: k,
          video: m
        };
        a.g.debug("Guessing stream type for", f.toString());
        f = !1;
        const n = g.a;
        g = k.audio.find(q => RI(q).Ca === n);
        m = _.Sj(_.t, h);
        m = c || m;
        1 !== h.length || m ? !l.length && 1 < h.length ? (a.g.debug("Guessing multiplexed audio+video"), g = _.t, h = [h.join(",")]) : k.audio.length && g ? (a.g.debug(eI), g = _.p, f = !0) : k.video.length ? (a.g.debug(eI), g = _.p) : (a.g.debug("Guessing video-only"), g = _.t) : (g = _.p, a.g.debug(eI));
        m = null;
        f || (m = yield MK(a, b, h, g, e, c));
        if (null == m) return null;
        k[g] = [m];
        g === _.p && c && l.forEach(q => {
          KJ(q, c.width);
          LJ(q, c.height);
          MJ(q, c.frameRate);
        });
        VI(k);
        return k;
      });
    },
    MK = function (a, b, c, d, e, f) {
      return _.w(function* () {
        const g = b.tag,
          h = b.playlist;
        return KK(a, h.a, () => _.w(function* () {
          var k = _.I(g, $H);
          let l = null;
          d === _.t && k && "NONE" !== k && (l = e.get(k));
          k = EK(d, c);
          return yield yK(a, {
            playlist: h,
            codecs: k,
            type: d,
            closedCaptions: l,
            primary: !1,
            kind: null,
            Zc: null,
            name: null,
            channelsCount: null,
            spatialAudio: !1,
            groupId: null,
            Ld: f
          });
        }));
      });
    },
    KK = function (a, b, c) {
      if (a.f.has(b)) return a = a.f.get(b), Promise.resolve(a);
      if (a.f.f.has(b)) return a.f.f.get(b) || null;
      c = c();
      a.f.set(b, c);
      return c;
    },
    BK = function (a, b, c, d) {
      return _.w(function* () {
        var e = b.a,
          f = b.U;
        var g = void 0 === g ? 0 : g;
        var h = (h = wI(b.aa, _.Ha)) ? Number(h.value) : g;
        g = [];
        !fK(a) && zJ(f);
        e = yield NK(a, b.f, e, f, h, c, d);
        if (0 === f.length) f = [];else {
          var k = [],
            l = [];
          for (var m of f) vI(m.aa, _.Ca) && 0 < l.length && (k.push(l), l = []), l.push(m);
          k.push(l);
          f = k;
        }
        for (m = 0; m < f.length; m++) k = f[m], l = yield OK(a, b.f, k, h, e, c, d, g[g.length - 1] || null, f.length), g.push(l), h += k.length;
        PK(g);
        return g;
      });
    },
    JK = function (a, b) {
      return _.w(function* () {
        var c = "EXT-X-MEDIA" !== b.name ? !0 : _.I(b, _.Kb) === hI;
        c = _.I(b, _.Mb, void 0, {
          required: c
        });
        if (!c) return null;
        const d = yI(a.w, c),
          {
            response: e,
            text: f
          } = yield bK(a, d);
        return cK(a, f, e.uri, c);
      });
    },
    PK = function (a) {
      if (1 < new Set(a.map(b => b.mimeType)).size) throw new _.G(_.E, 4, 4045, "Media playlist contains discontinuities with different MIME types. This is valid, but not yet supported.");
    },
    QK = function (a) {
      a = a.map(b => b.a ? b.a : b.f.map(c => _.I(c, _.Mb)) || null).map(b => _.mt(b));
      if (1 < new Set(a).size) throw new _.G(_.E, 4, 4044, "Unexpected change in file format.");
    },
    RK = function (a, b, c) {
      a = sI(a.aa, _.Ga);
      if (1 < a.length) throw new _.G(_.E, 4, 4020);
      a = a[0];
      if (!a) return null;
      const d = mI(a, _.Mb),
        e = yI(b, d);
      b = JI(a, null, !0);
      return new _.IH(() => [e], b.wa, b.ka, c);
    },
    SK = function (a, b, c, d, e, f, g) {
      const h = [];
      for (const q of rI(b.f)) {
        b = q.tf;
        const u = q.item;
        {
          var k = 0 === b,
            l = k ? a : h[h.length - 1],
            m = k ? d : l.endTime,
            n = Number(_.I(u, bI));
          n = m + n;
          k = JI(u, k ? null : l.ka, k);
          l = _.I(u, _.Mb);
          const v = (l = yI(g, l)) ? [l] : [];
          m = new KI(c, m, n, () => v, k.wa, k.ka, e, f, !1, null, null, null, null);
          if ("YES" === _.I(u, "INDEPENDENT") || 0 === b) m.h = !0;
          h.push(m);
        }
      }
      return h;
    },
    TK = function (a, b, c, d, e, f, g, h, k, l, m) {
      const n = c.aa;
      var q = c.a;
      0 < sI(n, _.Ga).length && (m = RK(c, k, g));
      const u = null != q,
        v = q ? [q] : [];
      if (!u && !a.a.lowLatencyMode) return null;
      a = 0;
      q = {
        wa: 0,
        ka: null
      };
      let y = null;
      u ? ({
        duration: c
      } = HI(n), a = e + c, q = GI(n, b ? b.ka : null)) : (b = SK(b, c, d, e, f, g, k), a = b[b.length - 1].endTime, y = b);
      h = h === _.be ? xI(n) : null;
      d = new KI(d, e, a, () => v, q.wa, q.ka, f, g, !1, null, h ? h.Uc : null, h ? h.kd : null, h ? h.durationPerTile : null, y, m);
      d.ja = l;
      return d;
    },
    NK = function (a, b, c, d, e, f, g) {
      return _.w(function* () {
        var h = d[0];
        if (null != a.A) return a.A;
        if (!fK(a) && a.B) try {
          var k = a.f.get(c);
          var l = k ? IJ(k, e) || null : null;
          if (!l) throw new _.G(_.E, 4, 4030, "Segment not found in pervious playlist update.");
          var m = l.startTime + l.Ba;
          a.g.debug(`Setting playlist start time to ${m}.`);
          return a.A = m;
        } catch (n) {
          if (h = a.f.get(c)) m = h.streams[h.streams.length - 1].U, m = m[m.length - 1], a.g.warn(`Last #EXT-X-MEDIA-SEQUENCE values ${h.streams[0].U[0].position} - ${m.position}` + `, new values ${e} - ${e + d.length - 1}.`, b, h);
          throw new _.G(_.E, 4, 4047, {
            message: "The updated playlist does not share any segments with the previous one.",
            uri: c
          });
        }
        m = yield UK(a, f, g, h);
        k = AJ(h.aa, a.w, m) || vJ();
        l = RK(h, b, k.keyInfo);
        k = MI(k.keyInfo, e);
        h = yield VK(a, l, h, k, m, g);
        a.g.debug(`Setting playlist start time to ${h}.`);
        return a.A = h;
      });
    },
    OK = function (a, b, c, d, e, f, g, h, k) {
      return _.w(function* () {
        var l = c[0],
          m = null == h;
        QK(c);
        const n = yield UK(a, f, g, l);
        var q = a.w,
          u = h ? h.Yg : null;
        var v = 1 === k;
        if (c.slice(1).some(yJ)) throw new _.G(_.E, 4, 4046, v ? "Media playlist contains an EXT-X-KEY tag with METHOD\x3dAES-128 which is not at the beginning of the playlist." : "Media playlist contains an EXT-X-KEY tag with METHOD\x3dAES-128 which is not marked by an EXT-X-DISCONTINUITY tag and is not at the beginning of the playlist.");
        if (vI(c[0].aa, _.Fa) || null === u) {
          if ((u = AJ(c[0].aa, q, n)) && u.type === lI) {
            if (1 < CJ(c)) throw new _.G(_.E, 4, 4046, iI + `${v ? "playlist" : "discontinuity"}.`);
            v = [u];
          } else v = BJ(c, q, n), v = 0 === v.length ? [vJ()] : v;
        } else if (q = BJ(c, q, n), u.type === _.Ce || u.type === kI) v = [u, ...q];else {
          if (0 < CJ(c)) throw new _.G(_.E, 4, 4046, iI + `${v ? "playlist" : "discontinuity"}.`);
          v = [u];
        }
        q = v;
        v = q[q.length - 1];
        q = wJ(q);
        u = RK(l, b, q.keyInfo) || (h ? h.Sg : null);
        var y = MI(q.keyInfo, d);
        const A = l.ja;
        l = m ? e : yield WK(a, A, u, l, d, y, n, g);
        m = [];
        for (y = 0; y < c.length; y++) {
          var D = c[y];
          const H = m[m.length - 1] || null,
            X = 0 === y ? 0 : H.endTime,
            vb = d + y,
            je = MI(q.keyInfo, vb);
          if (D = TK(a, H, D, vb, X, l, je, f, b, A, u)) u = D.initSegment, m.push(D);
        }
        return {
          U: m,
          vg: q,
          Yg: v,
          mimeType: n,
          endTime: m[m.length - 1].endTime,
          ja: A,
          Sg: u
        };
      });
    },
    UK = function (a, b, c, d) {
      return _.w(function* () {
        const e = d.a;
        var f = wI(d.aa, _.Ga);
        f = f ? mI(f, _.Mb) : null;
        return XK(a, b, c, e, f);
      });
    },
    VK = function (a, b, c, d, e, f) {
      return _.w(function* () {
        if (null == c.a) return 0;
        const g = LI(c, d);
        return GJ(a.O, b, g, e, f, null != d);
      });
    },
    WK = function (a, b, c, d, e, f, g, h) {
      return _.w(function* () {
        if (a.J.has(b)) return a.J.get(b);
        const k = yield VK(a, c, d, f, g, h);
        a.g.debug(`Segment #${e} has raw media timestamp ` + `${k} s.`);
        a.J.set(b, k);
        return k;
      });
    },
    XK = function (a, b, c, d, e) {
      return _.w(function* () {
        const f = [d, e].filter(Boolean);
        var g = _.Vj(f, b, c);
        if (g) return g;
        g = _.ij([d], a.a.attemptParameters);
        g.method = "HEAD";
        g.type = 2;
        g = (yield a.G(g)).headers["content-type"];
        if (!g) throw new _.G(_.E, 4, 4021, "Unknown MIME type of: " + f.join(", "));
        return g.split(";")[0];
      });
    },
    cJ = class {
      constructor(a, b, c, d) {
        if (1 === b) {
          if (!d || 0 === d.length) throw new _.G(_.E, 4, 4043, "Media playlist must have at least one segment!");
          if (null == d[0].a) throw new _.G(_.E, 4, 4043, "Media playlist must have at least one full segment!");
        }
        this.f = a;
        this.a = "";
        this.type = b;
        this.aa = c;
        this.U = 1 === b ? d : null;
      }
    },
    iJ = class {
      constructor(a, b, c) {
        c = void 0 === c ? [] : c;
        this.aa = b;
        this.a = a;
        this.f = c;
        this.ja = -1;
      }
    },
    KI = class extends _.uC {
      constructor(a, b, c, d, e, f, g, h, k, l, m, n, q, u, v) {
        u = void 0 === u ? [] : u;
        super(a, b, c, d, e, f, g, void 0 === h ? null : h, void 0 === k ? !1 : k, void 0 === l ? null : l, void 0 === m ? null : m, void 0 === n ? null : n, void 0 === q ? null : q, u, void 0 === v ? null : v);
        this.ja = 0;
      }
    },
    YK = class {
      constructor() {
        this.f = 0;
        this.a = new Map();
      }
    },
    ZK = class {
      constructor() {
        this.a = new Map();
        this.f = new Map();
      }
      set(a, b) {
        this.f.set(a, b);
        return b.then(c => this.a.set(a, c)).catch(() => {}).then(() => this.f.delete(a));
      }
      has(a) {
        return this.a.has(a);
      }
      get(a) {
        return this.a.get(a) || null;
      }
      values() {
        return Array.from(this.a.values());
      }
      clear() {
        this.f.clear();
        this.a.clear();
      }
    },
    kK = new _.R("clpp.hls.DiscontinuityUtils");
  var EJ = {
      "com.apple.streamingkeydelivery": function (a, b) {
        if (b === _.kg) throw new _.G(_.E, 4, 4041);
        if (mI(a, fI) === gI) throw new _.G(_.E, 4, 4044, "EXT-X-KEY:METHOD SAMPLE-AES-CTR is not supported in FairPlay.");
        return _.xB(_.sd, [{
          initDataType: _.If,
          initData: new Uint8Array(0)
        }], "");
      },
      "com.microsoft.playready": uJ,
      "urn:uuid:9a04f079-9840-4286-ab92-e65be0885f95": uJ,
      "urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed": function (a) {
        var b = mI(a, fI);
        if (!sJ.includes(b)) return tJ.error("Widevine in HLS is only supported with [", sJ.join(", "), "], not", b), null;
        b = mI(a, _.Mb);
        b = _.Bs(b);
        b = _.J(b.data);
        b = _.xB(_.xd, [{
          initDataType: _.ad,
          initData: b
        }], "");
        if (a = _.I(a, "KEYID")) b.keyIds = [a.substr(2).toLowerCase()];
        return b;
      }
    },
    sJ = ["SAMPLE-AES", gI],
    tJ = new _.R("clpp.hls.DrmParser");
  var DJ = new _.R("clpp.hls.EncryptionUtils");
  var $K = class {
    constructor(a) {
      this.g = new _.R("clpp.hls.MediaReader");
      this.f = a;
      this.a = {
        attemptParameters: _.ej()
      };
    }
  };
  var CK = class {
    constructor(a) {
      this.streams = a;
    }
    length() {
      return this.streams.length;
    }
  };
  var AK = class {
    constructor(a) {
      this.a = a;
    }
    length() {
      return this.a.length;
    }
  };
  var aL = class {
    constructor() {
      this.a = this.D = null;
      this.R = 1;
      this.f = new ZK();
      this.w = "";
      this.M = new YK();
      this.F = 0;
      this.j = null;
      this.h = new _.mk(() => {
        this.Pb();
      });
      this.l = _.Tb;
      this.B = null;
      this.C = new _.ut();
      this.I = this.A = null;
      this.g = new _.R("clpp.hls.HlsParser");
      this.J = new Map();
      this.O = new $K(this.G.bind(this));
      this.L = !1;
      this.H = null;
      this.K = !1;
      this.o = new Map();
    }
    configure(a) {
      this.a = a;
      this.O.a = a;
    }
    setCurrentVariant(a) {
      this.j = a;
    }
    start(a, b) {
      const c = this;
      return _.w(function* () {
        if (c.L) throw Error("start() may be called only once!");
        c.L = !0;
        c.D = b;
        const {
          response: d,
          text: e
        } = yield bK(c, a);
        c.w = d.uri;
        const f = yield hK(c, e);
        c.B = f;
        const g = c.F;
        c.h && 0 < g && c.h.fa(g);
        return f;
      });
    }
    requestStop() {
      this.K = !0;
    }
    stop() {
      this.h && (this.h.stop(), this.h = null);
      const a = [];
      this.C && (a.push(this.C.destroy()), this.C = null);
      this.f.clear();
      this.B = this.a = this.D = this.j = null;
      this.o.clear();
      return Promise.all(a);
    }
    update() {
      const a = this;
      return _.w(function* () {
        const b = a.B;
        if (!fK(a) && b) {
          var c = b.presentationTimeline;
          "LIVE" === a.l && (a.A = null);
          var d = Array.from(a.f.values());
          if (a.a.lowLatencyMode && null !== a.j) {
            const g = aK(a);
            d = d.filter(h => g.includes(RI(h).Ca));
          }
          var e = yield jK(a, d);
          d = e.map(g => g.playlist);
          lK(a, d);
          e = yield oK(a, e);
          PJ(b.periods, e).forEach(g => {
            a.D.filterNewPeriod(g);
          });
          eK(a, e);
          var f = pK(a, d);
          f = qK(a, d, f);
          b.cues = gK(a, f);
          RJ(b.periods, e);
          $J(c, e);
          if (d = BI(d)) !0 !== a.a.keepTimelineLive && rK(a, c, e), a.h.stop(), a.h = null;
          if (a.D.onUpdate) a.D.onUpdate(b);
          if (d && a.D.onTurnedStatic) a.D.onTurnedStatic();
          a.K && (yield a.stop());
          return b;
        }
      });
    }
    Pb() {
      const a = this;
      return _.w(function* () {
        a.g.debug(_.KA);
        if (a.D) try {
          yield a.update(), a.h && 0 < a.F && a.h.fa(a.F);
        } catch (b) {
          if (!(b instanceof _.G)) throw b;
          a.D && (a.D.onError(b), 1 === b.severity && a.h && a.h.fa(.1));
        }
      });
    }
    G(a) {
      if (!this.C) throw new _.G(_.E, 7, 7001);
      a = this.D.networkingEngine.fetch(a);
      _.tj(this.C, a);
      return a.W;
    }
  };
  _.x("clpp.hls.HlsComponent", class extends _.qu {
    f() {
      _.fs.m3u8 = aL;
      _.ds[_.hl] = aL;
      _.ds[jI] = aL;
    }
    a() {
      _.TC("m3u8");
      _.UC(_.hl);
      _.UC(jI);
    }
    id() {
      return "hls";
    }
  });
};
f.call(g, window);