<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">Rename</div>
  <div class="modal-body cl-form">

    <label for="dest_name" class="form-label">New file name</label>
    <input id="dest_name" type="text" [(ngModel)]="dest_name">

  </div>
  <div class="modal-footer">
    <span class="modal-footer-left cl-spacing-box cl-spacing-none">
                    <button type="button" class="btn btn-sm cl-btn-outline-text"
                            (click)="close()">
                        <i class="fas fa-angle-left"></i><span>Close</span>
                    </button></span>
    <span class="modal-footer-center">
        <div style="margin-top: -8px;">
          <app-message-field #moveMsg/>
        </div>
      </span>
    <span class="modal-footer-right">
                    <app-do-button type="button" [messageField]="moveMsg" (actionTriggered)="rename($event);">
                        <span>Rename file</span><i class="fas fa-angle-right"></i>
                    </app-do-button>
      </span>
  </div>
  </div>
</div>
