import {Component, Inject, OnInit} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {BannerService} from '../../../banner/banner.service';
import gql from 'graphql-tag';
import {Mutation, PoItem, Query as WorkflowQuery} from '../../../types-workflow';
import {GET_PROCESS} from '../../../queries';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-finalize-po-dialog',
  templateUrl: './finalize-po-dialog.component.html',
  styleUrls: ['./finalize-po-dialog.component.scss'],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
  ]
})
export class FinalizePoDialogComponent implements OnInit {
  poItemsInitial: PoItem[];
  poItems: PoItem[];
  publicationMode = 'unpublished';
  publishing = false;

  constructor(public dialogRef: DialogRef,
              private apollo: Apollo,
              private banner: BannerService,
              @Inject(DIALOG_DATA) public data: { po_name: string }) {
  }

  publicationChanged() {
    switch (this.publicationMode) {
      case 'unpublished':
        this.poItems = this.poItemsInitial.filter(poitem => poitem.publish_process?.state !== 'SUCCESS')
        break;
      case 'all':
        this.poItems = this.poItemsInitial.slice();
        break;
      case 'poonly':
        this.poItems = []
    }
  }

  remove(poitem_to_delete) {
    this.publicationMode = 'selected'
    this.poItems = this.poItems.filter(item => item.po_item_id !== poitem_to_delete.po_item_id)
  }

  ngOnInit(): void {
    const query = gql`
      query GetPoItemPublishStatus($po_name: String!) {
        list_POs(input: { filter: { po_name: { eq: $po_name}}} ){
          pos {
            id
            poitems {
              id
              po_item_id
              publish_process {
                id
                state
                start_date
                end_date
              }
              workflow_process {
                id
                state
                start_date
                end_date
              }
              vtk_template
              po_destination
              checkpoint_content_uploaded
              checkpoint_content_complete
              checkpoint_encodes_done
            }
          }
        }
      }
    `
    this.apollo.use('workflow').query<WorkflowQuery>({
      query: query,
      variables: {
        'po_name': this.data.po_name
      }
    }).subscribe(res => {
        this.poItemsInitial = res.data.list_POs.pos[0].poitems.slice()
          .filter(item => item.checkpoint_encodes_done)
          .filter(item => !(item.po_destination === null || item.po_destination === undefined || item.po_destination === 'manual_distribution'));

        this.publicationChanged();
      }
    )
  }

  publish() {
    this.publishing = true;
    this.apollo.use('workflow').mutate<Mutation>({
      mutation: gql`
        mutation finalize_po($po_name: String!, $publish_po_item_ids: [String!]!)  {
          publish(input: { po_name: $po_name, skip_finalize: false, publish_po_item_ids: $publish_po_item_ids}) {
            id
            state
            message
            input
          }
        }
      `,
      variables: {
        po_name: this.data.po_name,
        publish_po_item_ids: this.poItems.map(v => v.po_item_id)
      }

    }).subscribe(e => {
        this.dialogRef.close();

        const subscription = this.apollo.use('workflow').watchQuery<WorkflowQuery>({
          query: GET_PROCESS,
          pollInterval: 2000,
          variables: {
            'id': e.data.publish.id
          }
        }).valueChanges.subscribe(({data}) => {
          if (data.process.state === 'IN_PROGRESS') {
            return
          }
          subscription.unsubscribe();
          if (data.process.state === 'FAILED') {
            this.banner.open('Publication of ' + this.data.po_name + ' failed.', ['Close']);
          }
        }, error => {
          this.banner.open(error, ['Close']);
          subscription.unsubscribe();
        })
      }
    )
  }

}
